<template>
    <v-container fluid class="mb-12">
        <v-row class="mb-2">
            <v-col cols="12">
                <div class="d-flex">
                    <v-breadcrumbs :items="breadcumbs">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item :to="item.href" class="text-subtitle-2 crumb-item" :disabled="item.disabled"
                                exact>
                                {{ item.text }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                    <v-snackbar v-model="snackbar.visible" :color="snackbar.color"
                        :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout"
                        :top="snackbar.position === 'top'">
                        <v-layout align-center pr-4>
                            <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                            <v-layout column>
                                <div>
                                    <strong>{{ snackbar.title }}</strong>
                                </div>
                                <div>{{ snackbar.text }}</div>
                            </v-layout>
                        </v-layout>
                        <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                            <v-icon>clear</v-icon>
                        </v-btn>
                    </v-snackbar>
                </div>
            </v-col>

            <v-col cols="12">
                <v-card outined>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <h6 class="blue-lcd mb-1 pa-1">Mill Id </h6>
                                <v-autocomplete dense solo class="ma-0 pa-1 border-12" hide-details=true v-model="millId"
                                    :items="millIds" default="" item-value="mill_id" item-text="mill_name" label="Mill Id"
                                    @change="(event) => getMainFilter(event)" clearable>
                                    <!-- :disabled="disabledRegion" -->
                                </v-autocomplete>
                            </v-col>


                            <v-col cols="12" xs="12" sm="3" md="2">
                                <h6 class="blue-lcd mb-1 pa-1">Mach Type </h6>
                                <v-autocomplete dense solo class="ma-0 pa-1 border-12" hide-details=true v-model="machType"
                                    :items="machTypes" default="" item-value="mach_type" item-text="descr" label="Mach Type"
                                      clearable @change="(event) => getFilterMachId(event)">
                                    <!-- :disabled="disabledRegion" -->
                                </v-autocomplete>
                            </v-col>

                            <v-col cols="12" xs="12" sm="3" md="2">
                                <h6 class="blue-lcd mb-1 pa-1">Mach Id </h6>
                                <v-autocomplete dense solo class="ma-0 pa-1 border-12" hide-details=true v-model="machId"
                                    :items="machIds" default="" label="Mach Id" item-value="mach_id" item-text="descr"
                                      clearable>
                                      <!-- @change="(event) => getBrand(event)" -->
                                    <!-- :disabled="disabledRegion" -->
                                </v-autocomplete>
                            </v-col>
                            
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <h6 class="blue-lcd mb-1 pa-1">Start Period <strong style="color:red;">*</strong><span></span></h6>
                                <v-menu ref="modal" 
                                v-model="modal" 
                                :close-on-content-click="false" 
                                transition="scale-transition" 
                                offset-y 
                                max-width="290px" 
                                min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        dense
                                        clearable
                                        solo 
                                        v-model="period" 
                                        label="Period" 
                                        persistent-hint 
                                        append-icon="mdi-calendar" 
                                        v-bind="attrs" 
                                        v-on="on" 
                                        class="ma-0 border-12" 
                                        hide-details=true>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker 
                                        v-model="period" 
                                        type="date" 
                                        no-title @input="modal = false">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <h6 class="blue-lcd mb-1 pa-1">To Period </h6>
                                <v-menu ref="modal_to_period" 
                                v-model="modal_to_period" 
                                :close-on-content-click="false" 
                                transition="scale-transition" 
                                offset-y 
                                max-width="290px" 
                                min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        dense
                                        clearable
                                        solo 
                                        v-model="period_to" 
                                        label="To Period" 
                                        persistent-hint 
                                        append-icon="mdi-calendar" 
                                        v-bind="attrs" 
                                        v-on="on" 
                                        class="ma-0 border-12" 
                                        hide-details=true>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker 
                                        v-model="period_to" 
                                        type="date" 
                                        no-title @input="modal_to_period = false">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>

                            <v-col cols="12" xs="12" sm="2" md="1">
                                <v-btn class="mt-8 border-12" style="padding: 19px;" small color="primary" elevation="2" @click="getPullData()">Search</v-btn>
                            </v-col>
                            <!-- <v-col cols="12" xs="12" sm="2" md="1">
                                <v-btn class="mt-8 border-12" style="padding: 19px;" small color="primary" elevation="2" >
                                    <download-excel
                                        class="text"
                                        :fetch           = "exportExcel"
                                        :fields="headersColumn"
                                        :before-generate = "startDownload"
                                        :before-finish   = "finishDownload">
                                        Export
                                    </download-excel> </v-btn>
                            </v-col> -->
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>

        <v-row class="mb-5">
            <v-col class="col-12">
                <v-card outlined class="pa-md-5">
                    <h6 class="cyan-text text-darken-3 m-0">Mach Prod Capacity</h6>
                    <div id="container1" style="height: 370px; width: 100%;"></div>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {MachOrderCapacity} from "../../../backend-api/kka/production_perfomance/mach_order_capacity"
import {backendApi} from "../../../backend-api/backend-api-sr"

export default {
    data() {
        return{
            loading: false,
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            timeout: 7500,
            search: '',
            breadcumbs: [
                {
                    text: 'KKA',
                    disabled: false,
                    href: '/admin/kka',
                },
                {
                    text: 'Production Performa',
                    disabled: true,
                    href: '/admin/kka',
                },
                {
                    text: 'Machine Production Capacity',
                    disabled: true
                }
            ],
            // filter
            millId: '',
            millIds: [],
            machType: '',
            machTypes: [],
            machId: '',
            machIds: [],
            // Table
            modal: false,
            period: '',
            modal_to_period: false,
            period_to: '',
            x: window.matchMedia("(max-width: 991px)"),
        }
    },
    async mounted(){
        this.$store.dispatch('setOverlay', true)
        await this.getFilterMill()
        this.$store.dispatch('setOverlay', false)
    },
    computed:{},
    methods:{
        tr_datatable(item) {
            const rowClass = 'tr_datatable'
            return rowClass;
        },
        async getFilterMill(){
            const _activeFlag = 'Y'
            const respData = await MachOrderCapacity.fetchMillFilter(`?active_flag=${_activeFlag}`, null, false, false, false)
            if (respData.status === 200) {
                const data = respData.data.data
                this.millIds = data.millData
            }
        },
        async getMainFilter(event){
            const _activeFlag = 'Y'
            const respData = await MachOrderCapacity.fetchMainFilter(`?mill_id=${this.millId}&active_flag=${_activeFlag}`, null, false, false, false)
            if (respData.status === 200) {
                const data = respData.data.data
                this.machTypes = data.machData
            }
        },
        async getFilterMachId(event){
            const _activeFlag = ''
            const respData = await MachOrderCapacity.fetchMachNumber(`?mill_id=${this.millId}&mach_type=${this.machType}&active_flag=${_activeFlag}`, null, false, false, false)
            if (respData.status === 200) {
                this.machIds = respData.data.data.data
            }
        },
        async getPullData(event){
            
            if (this.period === '') {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: "Please fill date start",
                    visible: true
                };
                return false;
            } else {
                this.$store.dispatch('setOverlay', true)
                var periodStart = '';
                var periodEnd = '';
                periodStart = this.period ? new Date(this.period).toISOString().substr(0, 10) : ''
                periodEnd = this.period_to ? new Date(this.period_to).toISOString().substr(0, 10) : new Date().toISOString().substr(0, 10)
                var reqBody = {
                    "mill_id": this.millId,
                    "mach_type": this.machType,
                    "mach_id": this.machId,
                    "mach_name": '',
                    "period_start": periodStart,
                    "period_end": periodEnd
                }
                const capTargetData = await MachOrderCapacity.fetchMachCapTarget(``, reqBody, false, false, false)
                const machPlanData = await MachOrderCapacity.fetchMachPlan(``, reqBody, false, false, false)
                const machProdData = await MachOrderCapacity.fetchMachProd(``, reqBody, false, false, false)
                if (capTargetData.status != 200 || machPlanData.status != 200 || machProdData.status != 200) {
                    this.$store.dispatch('setOverlay', false)
                    this.snackbar = {
                        color: "warning",
                        icon: "mdi-alert-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Warning",
                        text: "Failed to fetch data from server",
                        visible: true
                    };
                    return false
                }

                const capDataJson = capTargetData.data.data.capacity
                var dt1 = [];
                capDataJson.forEach(item => {
                    dt1.push({
                        "label": item.label,
                        "y": parseFloat(item.y)
                    });
                });
                const targetDataJson = capTargetData.data.data.target
                var dt2 = [];
                targetDataJson.forEach(item => {
                    dt2.push({
                        "label": item.label,
                        "y": parseFloat(item.y)
                    });
                });
                const machPlanDataJson = machPlanData.data.data
                var dt3 = [];
                machPlanDataJson.forEach(item => {
                    dt3.push({
                        "label": item.label,
                        "y": parseFloat(item.y)
                    });
                });
                const machProdDataJson = machProdData.data.data
                var dt4 = [];
                machProdDataJson.forEach(item => {
                    dt4.push({
                        "label": item.label,
                        "y": parseFloat(item.y)
                    });
                });
                this.$store.dispatch('setOverlay', false)
                this.getchart(dt1, dt2, dt3, dt4, "container1", 'column', "", "")
            }
        },

        addSymbols(e) {
            var suffixes = ["", "K", "M", "B"];
            var order = Math.max(Math.floor(Math.log(e.value) / Math.log(1000)), 0);

            if(order > suffixes.length - 1)
                order = suffixes.length - 1;

            var suffix = suffixes[order];
            return CanvasJS.formatNumber(e.value / Math.pow(1000, order)) + suffix;
        },
        toggleDataSeries(e) {
            if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
                e.dataSeries.visible = false;
            } else {
                e.dataSeries.visible = true;
            }
            e.chart.render();
        },

        getchart(dt1, dt2, dt3, dt4, container, type, title, subtitle){
            var chart = new CanvasJS.Chart(container, {            
                animationEnabled: true,
                theme: "light2",
                backgroundColor: "transparent",
                exportEnabled: true,
                title:{
                    text: title,
                    fontFamily: "Calibri",
                    fontSize: 20
                },
                subtitles:[
                    {
                        text: subtitle,
                        //Uncomment properties below to see how they behave
                        fontFamily: "Calibri",
                        fontColor: "red",
                        fontSize: 12
                    }
                ],
                toolTip:{
                    shared:true,
                    yValueFormatString: "#,###",
                },
                legend: {
                    cursor: "pointer",
                    itemclick: this.toggleDataSeries,
                    fontSize: 11
                },
                axisX: {
                    // labelFormatter: function (e) {
                    //     return (new Date(e.value - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
                    // },
                    interval: 1,
                    intervalType: "day"
                },
                axisY: {
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true
                    },
                    title: "Length (M)",
                    labelFormatter: this.addSymbols,
                },

                data: [
                    { 
                        type: type,
                        name: "Plan",   
                        showInLegend: true,
                        color: "#FFC300",
                        dataPoints: dt3
                    },
                    {
                        type: type,
                        name: "Production",
                        showInLegend: true,
                        dataPoints: dt4
                    },
                    {
                        type: "line",
                        name: "Capacity",
                        showInLegend: true,
                        color: "#01579B",
                        dataPoints: dt1
                    },
                    {
                        type: "line",
                        name: "Target",
                        showInLegend: true,
                        color: "#D32F2F",
                        dataPoints: dt2
                    },
                ]
            })
            chart.render();
        },

        getChartPie(dt1, dt2, dt3, container, type){
            var chart1 = new CanvasJS.Chart(container, {
                animationEnabled: true,
                theme: "light2",
                backgroundColor: "transparent",
                exportEnabled: true,
                // title: {
                //     text: title,
                //     fontFamily: "Calibri",
                //     fontSize: 20
                // },
                // subtitles:[
                //     {
                //         text: label,
                //         //Uncomment properties below to see how they behave
                //         fontFamily: "Calibri",
                //         fontColor: "red",
                //         fontSize: 12
                //     }
                // ],
                axisX: {
                    // labelFormatter: function (e) {
                    //     return (new Date(e.value - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
                    // },
                    interval: 3,
                    intervalType: "day"
                },
                axisY: {
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true
                    },
                    // title: "TON",
                    labelFormatter: this.addSymbols,
                },
                toolTip:{
                    shared:true,
                    // yValueFormatString: "#,###",
                },
                legend: {
                    cursor: "pointer",
                    // itemclick: this.explodePie
                },
                data: [
                {
                    type: type,
                    // indexLabel: "{label} {y} (#percent%)",
                    indexLabelFontSize: 14,
                    showInLegend: true,
                    yValueFormatString: "#,###",
                    toolTipContent: "{name}: <strong>{y} Kg</strong>",
                    // yValueFormatString: "#,###,,,.##",
                }]
            });

            var x = window.matchMedia("(max-width: 991px)");
            chart.options.data[0].dataPoints = dt1;

            if (x.matches) {

                for(var i = 0; i < chart.options.data.length; i++){
                    chart.options.data[i].indexLabelFontSize = 8;
                }
                chart.render();
            }
            chart.render();
        },
    },
    watch:{},
}
</script>

<style scoped>
    .tr_datatable{
        background-color: #F5F7F8 !important;
    }
</style>