import {backendApi} from "../../backend-api-sr"

export const MachOrderCapacity = (() =>{
    const fetchMillFilter = ( async (subPath) => {
        var url = '/api/v3/kka/production-performance/order-capacity/filter-mill'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })
    const fetchMainFilter = ( async (subPath) => {
        var url = '/api/v3/kka/production-performance/order-capacity/filter-mach-type'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })

    const fetchMachNumber = ( async (subPath) => {
        var url = '/api/v3/kka/production-performance/order-capacity/filter-mach-number'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })

    const fetchMachCapTarget = ( async (subPath, reqBody) => {
        var url = '/api/v3/kka/production-performance/order-capacity/machine-capacity-target'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })

    const fetchMachPlan = ( async (subPath, reqBody) => {
        var url = '/api/v3/kka/production-performance/order-capacity/machine-plan'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })

    const fetchMachProd = ( async (subPath, reqBody) => {
        var url = '/api/v3/kka/production-performance/order-capacity/machine-prod'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })

    return {fetchMillFilter, fetchMainFilter, fetchMachNumber, fetchMachCapTarget, fetchMachPlan, fetchMachProd};
})()